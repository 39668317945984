<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <h2>Reseller</h2>
      <div class="mb-4 ml-auto">
        <a-input-search
          placeholder="Search Reseller"
          enter-button
          :value="$route.query.q"
          @change="debouncedOnSearch"
        />
      </div>
    </div>
    <div class="bg-white" style="overflow-x: auto">
      <a-table
        :columns="columns"
        :data-source="reseller_list"
        :pagination="false"
        :row-key="data => data.seller_hashid"
        class="table-reseller"
        :loading="isLoading"
      >
        <span slot="store_status" slot-scope="store_status, record">
          <!-- <a-tag
                  :color="store_status === 'pending' ? 'volcano' : 'geekblue'"
                >
                  {{ store_status }}
                </a-tag> -->
          <a-switch
            size="small"
            :default-checked="store_status === 'active' ? true : false"
            :disabled="isUpdateStatus"
            @change="checked => updateStatus(checked, record)"
          />
        </span>

        <template #level="text, record">
          <div class="d-flex align-items-center">
            <div>{{ record.level_name }}</div>
            <a-button
              v-if="record.store_status === 'active'"
              icon="swap"
              type="dashed"
              size="small"
              class="ml-2 text-muted"
              @click.prevent="!isUpdateStatus && openModalLevel(record, 'update')"
            />
          </div>
        </template>

        <template slot="actions" slot-scope="text, record">
          <div class="d-flex justify-content-end flex-wrap" style="gap: .5rem">
            <div
              v-if="record.store_status === 'pending'"
              class=""
              @click.prevent="openModalLevel(record, 'confirm')"
            >
              <a-button size="small">
                Confirmation Register
              </a-button>
            </div>
            <div class="">
              <a-tooltip>
                <template #title>
                  View detail
                </template>
                <a-button
                  size="small"
                  :disabled="isUpdateStatus"
                  icon="eye"
                  @click.prevent="() => toDetail(record)"
                />
              </a-tooltip>
            </div>
          </div>
        </template>
      </a-table>
    </div>

    <a-pagination
      v-if="total > 0"
      v-model="page"
      :total="total"
      :page-size="pageSize"
      class="mt-3 d-flex justify-content-end"
      :show-total="total => `Total ${total} items`"
      @change="
        page => $router.push({ query: { ...$route.query, page } })
      "
    />

    <a-modal
      v-model="modalLevelModels"
      :title="
        flagModal === 'update'
          ? 'Update Level Model'
          : 'Seller Register Confirmation'
      "
      :footer="null"
    >
      <ModalLevelModels
        :level_model_id="level_model_id"
        :seller_hashid="seller_hashid"
        :store_status="store_status"
        :flag-modal="flagModal"
        @submitLevelModels="submitLevelModels"
      />
    </a-modal>
    <router-view />
  </div>
</template>

<script>
import ModalLevelModels from './components/ModalLevelModels'
import BaseURL from '@/services/axios'
import _ from 'lodash';

import { mapState, mapActions } from 'vuex'

const normalizePage = value => {
  if (isNaN(value)) {
    return 1
  }

  const page = Number(value)

  return page < 1 ? 1 : page
}

export default {
  components: {
    ModalLevelModels,
  },
  data() {
    return {
      isSearch: false,
      modalLevelModels: false,
      seller_hashid: null,
      store_status: null,
      level_model_id: null,
      flagModal: null,
      query: this.$route.query,
      total: 0,
      timeout: 0,
      position: {
        x: 0,
        y: 0,
      },
      isUpdateStatus: false,
    }
  },
  computed: {
    ...mapState('web_platform_channel/reseller', {
      isLoading: state => state.isLoading,
      reseller_list: state => state.reseller_list,
      search: state => state.search,
      columns: state => state.columns,
    }),

    page() {
      return normalizePage(this.$route.query.page)
    },

    pageSize() {
      return Number(this.$route.query.pageSize) || 30
    },
  },
  watch: {
    '$route.query'() {
      this.fetchResellerList()
    },
  },
  created() {
    this.debouncedOnSearch = _.debounce(this.onSearch, 300)
  },
  mounted() {
    this.fetchResellerList()
  },
  methods: {
    ...mapActions('web_platform_channel/reseller', [
      'getResellerList',
      'searchReseller',
    ]),

    toDetail(record) {
      this.position.x = window.scrollX
      this.position.y = window.scrollY

      this.$router.push({
        name: 'web-affiliate.detail',
        query: {
          ...this.$route.query,
          channel_id: this.$route.params.id,
          seller_id: record.seller_id,
          store_status: record.store_status,
        },
      })
    },

    fetchResellerList() {
      this.$store
        .dispatch('web_platform_channel/reseller/getResellerList', {
          ...this.$route.query,
          ...this.$route.params,
          with_loading: true,
          page: this.page,
          limit: this.pageSize,
        })
        .then(response => {
          this.total = response.rows
        })
        .finally(() => {
          this.isUpdateStatus = false
        })
    },

    onSearch(e) {
      const value = e.target.value

      this.$router.push({
        query: {
          ...this.$route.query,
          q: value,
          page: 1,
        },
      })
    },
    changeDateFormat(value) {
      return this.$moment(value).format('DD MMMM YYYY')
    },
    openModalLevel(record, flag) {
      this.seller_hashid = record.seller_id
      this.store_status = record.store_status
      this.level_model_id = record.level_id
      this.flagModal = flag
      this.modalLevelModels = true
    },
    submitLevelModels() {
      // this.getResellerList()
      this.fetchResellerList()
      this.flagModal = null
      this.seller_hashid = null
      this.store_status = null
      this.level_model_id = null
      this.modalLevelModels = false
    },
    updateStatus(checked, record) {
      this.isUpdateStatus = true
      BaseURL({
        method: 'put',
        url: `/channel/store/reseller/cmd/seller/${record.seller_id}`,
        headers: {
          'Content-Type': 'application/json',
          'business-id': this.$route.query.business_id,
        },
        data: {
          levelmodel_id: record.level_id,
          store_status: record.store_status,
          store_enable: checked ? 1 : 0,
        },
        params: {
          channel_id: this.$route.params.id,
        },
      })
        .then(() => {
          this.fetchResellerList('not_loading')
        })
        .catch(error => {
          this.$notification.error({
            message: error?.response?.data?.message || error.message,
          })
          this.isUpdateStatus = false;
        })
    },
  },
}
</script>

<style lang="scss">
.reseller_loader {
  .ant-spin-dot-item {
    background-color: #fff;
  }
}

.table-reseller {

  .ant-table {
    font-size: 13px;
  }

  tr {
    > th {
      font-weight: bold !important;
    }

    > td {
      padding: 8px 16px !important;
    }
  }
}
</style>
