<template>
  <a-form-model ref="ruleForm" :model="form">
    <a-form-model-item
      prop="level_model"
      label="Select Level Model"
      :rules="[{
        required: true,
        message: 'level model is required',
      }]"
    >
      <a-select
        v-model="form.level_model"
        placeholder="Select Level Model"
        class="w-50"
      >
        <a-select-option v-for="item in levelModels" :key="item.id">
          <div class="d-flex justify-content-between" :value="item.id">
            <div>{{ item.level_name }}:</div>
            <div>{{ item.default_discount }}%</div>
          </div>
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" size="large" @click="submit">
        Submit
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import BaseURL from '@/services/axios'

export default {
  name: 'confirm.seller',
  props: ['seller_hashid', 'store_status', 'level_model_id', 'flagModal'],
  data: function () {
    return {
      levelModels: null,
      form: {
        level_model: this.level_model_id,
      },
    }
  },
  watch: {
    level_model_id(value) {
      this.form.level_model = value
    },
  },
  created() {
    this.fetchLevelModels()
  },
  methods: {
    async fetchLevelModels() {
      try {
        const { data: response } = await BaseURL({
          method: 'get',
          url: '/channel/store/reseller/query/reseller/level',
          headers: {
            'Content-Type': 'application/json',
            'business-id': this.$route.query.business_id,
          },
          params: {
            channel_id: this.$route.params.id,
          },
        })
        this.levelModels = response.data
      } catch (error) {
        console.log(error)
      }
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return
        try {
          await BaseURL({
            method: 'put',
            url: `/channel/store/reseller/cmd/seller/${this.seller_hashid}`,
            headers: {
              'Content-Type': 'application/json',
              'business-id': this.$route.query.business_id,
            },
            data: {
              levelmodel_id: this.form.level_model,
              store_status: this.store_status,
              store_enable: 1,
            },
            params: {
              channel_id: this.$route.params.id,
            },
          })
          this.$notification.success({
            message:
              this.flagModal === 'update'
                ? 'Success update level models'
                : 'Seller register confirmation success',
          })
          this.$emit('submitLevelModels')
          this.$refs.ruleForm.resetFields()
        } catch (error) {
          this.$notification.error({
            message: error?.response?.data?.message || error.message,
          })
        }
      })
    },
  },
}
</script>
